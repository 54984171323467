import { ForwardedRef, forwardRef, KeyboardEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

import phoneHandler from '@/functions/phoneHandler';
import ConditionalRenderer from '../ConditionalRenderer';
import Skeleton from '../Skeleton';
import { BaseInputProps, OmitDefaultClassname } from './BaseInput';

type InputColorType = 'primary' | 'warning' | 'disabled';

export type TextInputProps = OmitDefaultClassname &
  BaseInputProps & {
    type?: 'text' | 'email' | 'phone' | 'password';
    color?: InputColorType;
    errorLabelText?: string;
    typeInput?: string;
    centerText?: boolean;
    maxLenght?: number;
    mask?(value: string): string;
    onClickIcon?(): void;
  };

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  TextInputComponent,
);

export default TextInput;

function TextInputComponent(
  props: TextInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const {
    label,
    className,
    icon,
    testId,
    type,
    typeInput,
    color = 'primary',
    errorLabelText,
    dataTestId,
    fontWeight,
    register,
    bgColor,
    fontSize,
    centerText,
    isLoading,
    labelPosition = 'top',
    errorLabelPosition = 'top',
    maxLenght,
    mask,
    disabled,
    onClickIcon,
    prefix,
    isOptional = false,
    removeDisableStyle,
    ...rest
  } = props;

  const name = rest.name || register?.name;

  const styles: {
    [key in InputColorType]: string;
  } = {
    warning: 'input-error focus-visible:ring-error-content',
    primary: 'input-primary focus-visible:ring-primary-content',
    disabled:
      'disabled:text-base-content disabled:cursor-default disabled:bg-base-100 disabled:border-none',
  };

  const maskHandler: KeyboardEventHandler<HTMLInputElement> = e => {
    const input = e.currentTarget;
    if (mask) {
      input.value = mask(input.value);
    }
  };

  const formatedLabel = isOptional
    ? `${label} (${t('optional').toLowerCase()})`
    : label;

  return (
    <div
      className={twMerge(
        'form-control relative flex text-[0.875rem] w-full',
        labelPosition === 'left' ? 'flex-row gap-3' : 'flex-col',
        className?.base,
      )}
    >
      <ConditionalRenderer condition={label}>
        <label
          htmlFor={rest.id || name}
          className={twMerge(
            'label leading-none p-0 flex',
            labelPosition === 'left' ? 'w-32' : '',
            className?.label,
          )}
        >
          {formatedLabel}
        </label>
      </ConditionalRenderer>

      <div className="flex-col justify-center flex relative grow">
        <ConditionalRenderer condition={errorLabelText}>
          <label
            data-testid="errorLabel"
            className={twMerge(
              'label !text-error p-0 text-[0.875rem]',
              errorLabelPosition === 'bottom' ? 'absolute top-full' : '',
              fontSize,
              className?.label,
            )}
          >
            {errorLabelText}
          </label>
        </ConditionalRenderer>
        <div className="flex items-center relative grow">
          <ConditionalRenderer
            condition={!isLoading}
            fallback={
              <Skeleton className="h-8 rounded-lg bg-primary-content grow" />
            }
          >
            <ConditionalRenderer condition={!!prefix}>
              <span
                className={twMerge(
                  'flex w-[5rem] font-500 text-[1rem] absolute pl-2 cursor-not-allowed text-primary grow',
                  className?.prefix,
                )}
              >
                {prefix}
              </span>
            </ConditionalRenderer>
            <input
              ref={ref}
              {...register}
              {...rest}
              placeholder={rest.placeholder}
              disabled={disabled}
              data-testid={testId || dataTestId}
              type={type}
              onKeyUp={type === 'phone' ? phoneHandler : maskHandler}
              maxLength={type === 'phone' ? 15 : maxLenght}
              className={twMerge(
                'input-sm focus-visible:ring focus:outline-none input input-bordered text-base-content grow',
                className?.input,
                styles[color],
                icon ? 'pr-8' : '',
                prefix ? 'pl-[5rem]' : '',
                styles['disabled'],
              )}
            />
          </ConditionalRenderer>
          <ConditionalRenderer condition={icon}>
            <figure
              onClick={onClickIcon}
              className={twMerge(
                'w-4 h-4 absolute right-2.5',
                onClickIcon ? 'cursor-pointer' : '',
                disabled ? 'pointer-events-none' : '',
                color === 'warning' ? 'text-error' : 'text-primary',
                className?.figure,
              )}
            >
              {icon}
            </figure>
          </ConditionalRenderer>
        </div>
      </div>
    </div>
  );
}
