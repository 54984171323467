import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import ConditionalRenderer from './ConditionalRenderer';
import Badge from './dataDisplay/Badge';
import Text from './dataDisplay/Text';
import ConditionalWrapper from './ConditionalWrapper';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import { Color as TooltipColor, Tooltip } from './dataDisplay/Tooltip';
import { Link } from 'react-router-dom';

export type SimpleTextIconProps = {
  testId?: string;
  title: string;
  icon?: ReactNode;
  count?: number;
  className?: ClassNameComponent;
  tooltip?: boolean;
  href?: string;
  tooltipColor?: TooltipColor;
  tooltipText?: string;
  truncate?: boolean;
};

export default function SimpleTextIcon({
  testId,
  title,
  icon,
  count,
  className,
  tooltip,
  href = '',
  tooltipColor,
  tooltipText,
  truncate,
}: SimpleTextIconProps) {
  return (
    <section
      className={twMerge(
        'flex gap-3.5 items-center select-none h-fit',
        truncate ? 'overflow-hidden' : '',
        className?.base,
      )}
    >
      <ConditionalRenderer condition={icon}>
        <ConditionalWrapper
          condition={!!count}
          wrapper={
            <Badge
              position="-right-1 top-0"
              className="border-none"
              count={count}
            />
          }
        >
          <div
            className={twMerge(
              'p-1 bg-accent text-base-100 rounded-full',
              className?.div,
            )}
          >
            {icon}
          </div>
        </ConditionalWrapper>
      </ConditionalRenderer>
      <ConditionalWrapper
        condition={!!tooltip}
        wrapper={
          <Tooltip
            text={tooltipText ?? title}
            classNameContainer={twMerge(
              className?.tooltip,
              truncate ? 'overflow-hidden' : '',
            )}
            color={tooltipColor}
          />
        }
      >
        <ConditionalWrapper
          condition={!!href}
          wrapper={
            <Link
              data-testid={testId}
              to={href}
              className={twMerge(
                'text-primary underline cursor-pointer hover:text-primary/40 transition-all duration-150 ease-in-out',
                truncate ? 'overflow-hidden' : '',
                className?.link,
              )}
            />
          }
        >
          <Text
            text={title}
            className={twMerge(
              'text-[0.875rem] xl:text-[1rem] uppercase text-start font-rubik font-500 h-fit',
              truncate ? 'truncate' : '',
              className?.text,
            )}
          />
        </ConditionalWrapper>
      </ConditionalWrapper>
    </section>
  );
}
