import { FC } from 'react';
import { ChevronDownIcon } from '@heroicons/react/outline';

import Text from '../common/dataDisplay/Text';
import { IconWithText } from '../common/dataDisplay/IconWithText';
import { twMerge } from 'tailwind-merge';
import { ClassNameComponent } from '@/utils/ClassNameComponent';

type SelectHeaderProps = {
  label?: string;
  selected?: string;
  className?: Pick<ClassNameComponent, 'base' | 'text'>;
};

const SelectFilterHeader: FC<SelectHeaderProps> = (
  props: SelectHeaderProps,
) => {
  const { label = '', className, selected = '' } = props;

  return (
    <section
      className={twMerge(
        'flex gap-1 items-center cursor-pointer',
        className?.base,
      )}
    >
      <Text text={label} className="text-14 lg:text-16" />
      <IconWithText
        icon={<ChevronDownIcon className="w-3" />}
        className={{
          base: 'gap-0.5 text-base-300 flex-row-reverse self-center',
          text: twMerge(
            'text-12 font-400 text-secondary lowercase',
            className?.text,
          ),
        }}
        text={selected}
      />
    </section>
  );
};

export default SelectFilterHeader;
