import { twMerge } from 'tailwind-merge';
import Text from './dataDisplay/Text';
import { zeroPad } from 'react-countdown';

type CountdownItemProps = {
  label: string;
  value: number;
  classNames: string;
};

const CountdownItem = ({ label, value, classNames }: CountdownItemProps) => (
  <div
    className={twMerge(
      'justify-center text-center items-center rounded p-1.5 flex flex-col',
      classNames,
    )}
  >
    <span className="font-rubik text-10">{label}</span>
    <Text format="poppins-600" text={zeroPad(value, 2)} className="text-32" />
  </div>
);

export default CountdownItem;
