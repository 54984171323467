import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import { LoadingIcon } from '@/components/icons';
import useVisibleElement from '@/data/hook/useVisibleElement';
import { fadeIn } from '@/utils/animations/commom';
import ConditionalRenderer from './ConditionalRenderer';
import IconButton from './buttons/IconButton';
import { debounce } from 'lodash';

type CarouselProps = {
  children: ReactNode;
  hasNextPage?: boolean;
  fetchingNextPage?: boolean;
  className?: string;
  listClassName?: string;
  onReachEnd?(): unknown | Promise<unknown>;
  hiddenButtons?: boolean;
};

type direction = 'left' | 'right';

export default function Carousel({
  children,
  hasNextPage,
  fetchingNextPage,
  className,
  listClassName,
  onReachEnd,
  hiddenButtons,
}: CarouselProps) {
  const listRef = useRef<HTMLUListElement>(null);

  const startRef = useRef<HTMLSpanElement>(null);

  const endRef = useRef<HTMLSpanElement>(null);

  const [disableStartButton, setDisableStartButton] = useState(false);

  const [disableEndButton, setDisableEndButton] = useState(false);

  const scrollValue = useRef(
    listRef.current ? listRef.current.clientWidth / 2 : 0,
  );

  const endLoadingRef = useRef<HTMLDivElement>(null);

  const options: IntersectionObserverInit = {
    root: listRef.current,
    rootMargin: '100px',
  };

  const endIntersecting = useVisibleElement(endLoadingRef, options);

  const start = useVisibleElement(startRef, options);

  const end = useVisibleElement(endRef, options);

  const scroll = (left: number) => {
    listRef.current?.scrollTo({
      left,
      behavior: 'smooth',
    });
  };

  const onScroll = (direction: direction) => {
    if (listRef.current) {
      const scrollLeft = listRef.current.scrollLeft;

      if (direction === 'left') {
        scroll(scrollLeft - scrollValue.current);
      } else {
        scroll(scrollLeft + scrollValue.current);
      }
    }
  };

  const reachEnd = useCallback(() => onReachEnd?.(), [onReachEnd]);

  useEffect(() => {
    if (endIntersecting) {
      reachEnd();
    }
  }, [endIntersecting, reachEnd]);

  useEffect(() => {
    if (start) setDisableStartButton(true);

    return () => setDisableStartButton(false);
  }, [start]);

  useEffect(() => {
    if (end) setDisableEndButton(true);

    return () => setDisableEndButton(false);
  }, [end]);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (listRef.current) {
        scrollValue.current = listRef.current.clientWidth / 2;
      }
    }, 200);

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={twMerge('relative flex items-center', className)}>
      <CarouselController
        icon={ChevronLeftIcon}
        direction="left"
        onClick={() => onScroll('left')}
        disable={hiddenButtons || disableStartButton}
      />
      <motion.ul
        {...fadeIn}
        ref={listRef}
        className={twMerge(
          'flex py-4 gap-4 w-full',
          listClassName,
          'overflow-x-scroll relative scrollbar-hide hover:overscroll-y-contain scroll-smooth snap-x snap-mandatory sm:snap-none',
        )}
      >
        <span className="absolute" ref={startRef} />
        {children}
        <ConditionalRenderer condition={hasNextPage}>
          <div
            data-positiontarget="end"
            className="flex w-full justify-center"
            ref={endLoadingRef}
          >
            <ConditionalRenderer condition={fetchingNextPage}>
              <LoadingIcon className="w-6 text-primary" />
            </ConditionalRenderer>
          </div>
        </ConditionalRenderer>
        <span ref={endRef} />
      </motion.ul>

      <CarouselController
        icon={ChevronRightIcon}
        direction="right"
        onClick={() => onScroll('right')}
        disable={hiddenButtons || disableEndButton}
      />
    </div>
  );
}

type CarouselControllerProps = {
  direction: direction;
  onClick: () => void;
  disable: boolean;
  icon: React.ElementType;
};

const CarouselController = ({
  direction,
  disable,
  onClick,
  icon: Icon,
}: CarouselControllerProps) => {
  const position = direction === 'left' ? '-left-7' : '-right-4';
  return (
    <IconButton
      icon={<Icon className="w-6 text-primary" />}
      className={twJoin(
        'absolute p-1 rounded-full z-10 bg-base-100 border border-neutral-content',
        position,
        disable && 'disabled invisible',
      )}
      onClick={onClick}
    />
  );
};
