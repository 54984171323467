import { EditorConfig } from '@ckeditor/ckeditor5-core/src/editor/editorconfig';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import { imageUpload } from '@/data/services/imageService';
import {
  default as Editor,
  default as MyEditor,
} from 'ckeditor5-custom-build/build/ckeditor';
import { decamelize } from 'humps';
import { CSSProperties, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageFolderEnum } from '../../models/CkEditor';
import Skeleton from '../common/Skeleton';
import {
  DisplayFormat,
  formatClasses,
} from '../common/dataDisplay/HtmlPreview';

interface CkeditorProps {
  content: string;
  folder: ImageFolderEnum;
  placeholder?: string;
  handleContentChange: (value: string) => void;
  onUploadStart?: (image: string | Blob) => void;
  onUploadEnd?: () => void;
  onBlur?: () => void;
  testId?: string;
  format?: DisplayFormat;
  disabled?: boolean;
  isLoading?: boolean;
  imageUploadBody?: object;
}

function MyCkeditor(props: CkeditorProps) {
  const {
    handleContentChange,
    folder,
    content,
    onBlur,
    format,
    placeholder,
    testId,
    disabled,
    isLoading,
    imageUploadBody,
    onUploadEnd,
    onUploadStart,
  } = props;
  const { t } = useTranslation('translation', {
    keyPrefix: 'ckeditor',
  });

  const config: EditorConfig = {
    heading,
    toolbar: {
      shouldNotGroupWhenFull: true,
      items: [
        'findAndReplace',
        '|',
        'heading',
        '|',
        {
          label: 'Font',
          icon: 'paragraph',
          items: [
            'fontfamily',
            'fontsize',
            'fontColor',
            'fontBackgroundColor',
            'highlight',
          ],
        },
        '|',
        'bold',
        'italic',
        'underline',
        '|',
        'code',
        'codeBlock',
        '|',
        {
          label: 'List',
          icon: false,
          items: ['bulletedList', 'numberedList', 'todoList'],
        },
        '|',
        'mediaEmbed',
        'uploadImage',
        'link',
        '|',
        'outdent',
        'indent',
        '|',
        'alignment',
        '|',
        'insertTable',
        'undo',
        'redo',
      ],
    },
    extraPlugins: [UploadPlugin, ClipboardPlugin],
    placeholder: placeholder || t('placeholder'),
  } as EditorConfig;

  const uploadAdapterRef = useRef<MyUploadAdapter | null>(null);

  // Atualiza o uploadAdapterRef quando a prop imageUploadBody é alterada
  useEffect(() => {
    if (uploadAdapterRef.current) {
      uploadAdapterRef.current.imageUploadBody = imageUploadBody || {};
    }
  }, [imageUploadBody]);

  class MyUploadAdapter {
    loader;
    imageUploadBody?: object;
    constructor(loader: { file: string | Blob }, url?: string) {
      this.loader = loader;
      this.imageUploadBody = imageUploadBody || {};
    }

    upload() {
      onUploadStart?.(this.loader.file);
      const promise = Promise.resolve(this.loader.file).then(res => res);
      return promise.then(async (file: string | Blob) => {
        const imageUploaded = await imageUpload({
          file,
          folder,
          extraBody: this.imageUploadBody,
        });
        onUploadEnd?.();
        return {
          default: imageUploaded.image,
        };
      });
    }

    abort() {}
  }

  function UploadPlugin(editor: {
    plugins: {
      get: (repository: string) => {
        createUploadAdapter: (loader: { file: string | Blob }) => void;
      };
    };
  }) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: {
      file: string | Blob;
    }) => {
      const adapter = new MyUploadAdapter(loader);
      uploadAdapterRef.current = adapter;
      return uploadAdapterRef.current;
    };
  }

  const removeCSSFromInlineStyle = (
    htmlString: string,
    ignoreCSSProperties: (keyof CSSProperties)[],
  ) => {
    let textoFormatado = htmlString;

    ignoreCSSProperties.forEach(property => {
      const regex = new RegExp(
        `${decamelize(property).replace('_', '-')}:[^;]+;`,
        'g',
      );
      textoFormatado = textoFormatado.replace(regex, '');
    });

    return textoFormatado;
  };

  function ClipboardPlugin(editor: typeof Editor) {
    editor.editing.view.document.on(
      'clipboardInput',
      (
        _: Event,
        data: {
          dataTransfer: DataTransfer;
          content: DocumentFragment;
        },
      ) => {
        const dataTransfer = data.dataTransfer;
        let htmlContent = dataTransfer.getData('text/html');

        if (!htmlContent) {
          return;
        }

        htmlContent = removeCSSFromInlineStyle(htmlContent, [
          'color',
          'backgroundColor',
        ]);

        const viewFragment = editor.data.processor.toView(htmlContent);
        data.content = viewFragment;
      },
    );
  }

  if (isLoading) {
    return (
      <div className="flex flex-col gap-1">
        <Skeleton className="bg-primary w-full h-9 rounded-lg" />
        <Skeleton className="bg-primary w-full h-24 rounded-lg" />
      </div>
    );
  }
  return (
    <div className={`${formatClasses[format || 'default']} text-base-content`}>
      <CKEditor
        id="CkEditor"
        config={config}
        editor={MyEditor}
        data-testid={testId}
        data={content}
        disabled={!!disabled}
        onChange={(_event: object, editor: { getData: () => string }) => {
          const data = editor.getData();
          handleContentChange(data);
        }}
        onBlur={(_event: object) => {
          if (onBlur) {
            onBlur();
          }
        }}
      />
    </div>
  );
}

const heading: EditorConfig['heading'] = {
  options: [
    {
      model: 'paragraph',
      title: 'Paragraph',
      class: 'ck-heading_paragraph',
    },
    {
      model: 'heading1',
      view: 'h1',
      title: 'Heading 1',
      class: 'ck-heading_heading1',
    },
    {
      model: 'heading2',
      view: 'h2',
      title: 'Heading 2',
      class: 'ck-heading_heading2',
    },
    {
      model: 'heading3',
      view: 'h3',
      title: 'Heading 3',
      class: 'ck-heading_heading2',
    },
  ],
};

export default MyCkeditor;
