import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterFormProps, SelectFilterProps } from '../../models/FilterConfig';
import { FilterPopup } from './FilterPopup';
import SelectFilterHeader from './SelectFilterHeader';
import { ClassNameComponent } from '@/utils/ClassNameComponent';

type Props = SelectFilterProps &
  FilterFormProps & {
    className?: Pick<ClassNameComponent, 'reference' | 'text'>;
  };

export default function SelectFilter(props: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });

  const {
    label,
    setValue,
    name,
    options,
    value,
    className,
    onClear,
    showOnClear,
  } = props;

  const option = useMemo<string>(() => {
    return (
      options.find(option => option.value === value)?.label ||
      t('select').toLowerCase()
    );
  }, [options, value, t]);

  function defaultOnClear() {
    setValue(name, undefined);
  }

  const handleOnClear = onClear || defaultOnClear;

  const showClear = showOnClear ?? value;

  return (
    <FilterPopup
      onClear={showClear ? handleOnClear : undefined}
      label={label}
      reference={
        <SelectFilterHeader
          label={label}
          selected={option}
          className={{ ...className, base: className?.reference }}
        />
      }
    >
      <ul className="min-h-6 flex flex-col gap-1">
        {options.map(option => (
          <li
            onClick={() => setValue(name, option.value)}
            role="button"
            key={String(option.value)}
            className="cursor-pointer flex items-center justify-between gap-6 hover:text-primary transition ease-in-out duration-300 text-14"
          >
            {option.label}
          </li>
        ))}
      </ul>
    </FilterPopup>
  );
}
