import { useTranslation } from 'react-i18next';
import Modal from './Modal';
import Text from '../dataDisplay/Text';
import RoundedButton from '../buttons/RoundedButton';
import { ReactNode } from 'react';
import ConditionalRenderer from '../ConditionalRenderer';
import { twJoin } from 'tailwind-merge';
import { scrollBarYClassName } from '@/utils/scrollBarClassName';

interface ModalWarningProps {
  visible: boolean;
  translationString: string;
  onClickConfirm: (e?: any) => void;
  onClickCancel: (e?: any) => void;
  isLoading?: boolean;
  objectTarget?: string | ReactNode | JSX.Element;
}

export default function ModalWarning(props: ModalWarningProps) {
  const {
    visible,
    translationString,
    onClickCancel,
    onClickConfirm,
    objectTarget,
    isLoading,
  } = props;
  const { t } = useTranslation('translation', {
    keyPrefix: `modalWarning.${translationString}`,
  });

  function renderAnswerKey() {
    return (
      <div
        id="modal-warning"
        className="flex flex-col gap-6 items-center w-full max-h-[calc(100vh-10em)] relative pb-14"
      >
        <Text
          text={t('attention')}
          size="text-18"
          format="rubik-500"
          className="text-primary"
        />

        {t('warning') && <Text text={t('warning')} size="text-16" />}
        <div
          className={twJoin(
            'flex flex-col gap-6 items-center w-full relative py-2',
            scrollBarYClassName,
          )}
        >
          {objectTarget && typeof objectTarget === 'string' ? (
            <Text
              text={objectTarget}
              size="text-20"
              className="text-primary"
              format="rubik-500"
            />
          ) : (
            objectTarget
          )}
        </div>
        <ConditionalRenderer condition={!!t('proceed')}>
          <Text text={t('proceed')} size="text-16" />
        </ConditionalRenderer>

        <div className="absolute flex w-full justify-around gap-6 bottom-0">
          <RoundedButton
            text={t('cancel')}
            color="neutral"
            className="w-full"
            onClick={onClickCancel}
          />

          <RoundedButton
            text={t('confirm')}
            className="w-full"
            onClick={onClickConfirm}
            loading={isLoading}
          />
        </div>
      </div>
    );
  }

  return (
    <Modal visible={visible} onClose={onClickCancel}>
      {renderAnswerKey()}
    </Modal>
  );
}
