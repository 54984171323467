import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { handleUserFullName } from '@/functions/handleUserFullName';
import User from '@/models/User';
import ConditionalWrapper from '../ConditionalWrapper';
import { Avatar } from './Avatar';
import Text from './Text';
import { Tooltip } from './Tooltip';

type AvatarNameProps = {
  user: User;
  className?: string;
  textClassName?: string;
  onClick?: () => void;
  tooltip?: boolean;
  href?: string;
};

export default function AvatarName({
  user,
  className,
  onClick,
  tooltip,
  href,
  textClassName,
}: AvatarNameProps) {
  return (
    <div
      onClick={onClick}
      className={`flex items-center ${
        onClick ? 'cursor-pointer' : ''
      } ${twMerge('gap-4 text-base-content', className)}`}
    >
      <Avatar userId={user.id} url={href} />
      <ConditionalWrapper
        condition={!!href}
        wrapper={
          <Link
            data-testid="link"
            to={href as string}
            className="text-inherit truncate"
          />
        }
      >
        <ConditionalWrapper
          condition={!!tooltip}
          wrapper={
            <Tooltip
              classNameContainer="truncate w-full"
              text={handleUserFullName(user)}
            />
          }
        >
          <Text className={textClassName} text={handleUserFullName(user)} />
        </ConditionalWrapper>
      </ConditionalWrapper>
    </div>
  );
}
