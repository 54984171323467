import { PropsWithChildren, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Text from '../common/dataDisplay/Text';
import Popup from '../common/Popup';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import ConditionalRenderer from '../common/ConditionalRenderer';
import { XIcon } from '@heroicons/react/outline';
import IconButton from '../common/buttons/IconButton';

type Props = PropsWithChildren & {
  label?: string;
  onClear?(): void;
  className?: ClassNameComponent;
  reference: ReactNode;
};

export function FilterPopup(props: Props) {
  const { label, children, className, onClear } = props;

  return (
    <fieldset className="flex gap-1 items-center">
      <Popup {...props} offset={[-2, -2]} placement="bottom-end">
        <section className="bg-base-100 p-3.5 shadow-default rounded-xl border border-neutral-content flex flex-col gap-3.5 w-56 max-w-64 max-h-60">
          <ConditionalRenderer condition={label}>
            <Text
              text={label}
              className={twMerge('text-primary font-500', className?.label)}
            />
          </ConditionalRenderer>
          {children}
        </section>
      </Popup>
      <ConditionalRenderer condition={onClear}>
        <IconButton
          icon={<XIcon className="text-error w-4 h-4" />}
          onClick={onClear}
        />
      </ConditionalRenderer>
    </fieldset>
  );
}
