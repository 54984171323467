import { ClassNameComponent } from '@/utils/ClassNameComponent';
import { ForwardedRef, forwardRef, Fragment, MouseEvent } from 'react';
import { twMerge } from 'tailwind-merge';
import OutlineButton, { OutlineButtonProps } from '../buttons/OutlineButton';

export type ToggleButton<T> = OutlineButtonProps & {
  value: T;
};

export type ToggleButtonSize = 'small' | 'medium' | 'large';

type ToggleButtonGroupCommom<T> = {
  buttons: ToggleButton<T>[];
  orientation?: 'horizontal' | 'vertical';
  size?: ToggleButtonSize;
  className?: ClassNameComponent;
  testId?: string;
};

export type onChangeToggle<T> = (
  value: T,
  e?: MouseEvent<HTMLButtonElement>,
) => void;

type ToggleButtonSingleProps<T> = {
  exclusive: true;
  value?: T;
  onChange?: onChangeToggle<T>;
} & ToggleButtonGroupCommom<T>;

type ToggleButtonMultiProps<T> = {
  exclusive?: false;
  value?: T[];
  onChange?: onChangeToggle<T[]>;
} & ToggleButtonGroupCommom<T>;

type ToggleButtonProps<T> =
  | ToggleButtonSingleProps<T>
  | ToggleButtonMultiProps<T>;

const stylesBySize: {
  [key in ToggleButtonSize]: string;
} = {
  small: 'px-2 py-0.5 !text-14',
  medium: 'px-3 py-1.5',
  large: 'h-10',
};

export default forwardRef(ToggleButtonGroup) as <T>(
  props: ToggleButtonProps<T>,
) => JSX.Element;

function ToggleButtonGroup<T>(
  { orientation, ...props }: ToggleButtonProps<T>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      ref={ref}
      className={twMerge(
        'flex gap-1.5 flex-wrap',
        orientation === 'vertical' && 'flex-col',
        props.className?.base,
      )}
    >
      <ToggleButtonHandler {...props} />
    </div>
  );
}

function ToggleButtonHandler<T>(props: ToggleButtonProps<T>) {
  if (props && props.exclusive) return <ToogleButtonGroupSingle {...props} />;
  return <ToogleButtonGroupMulti {...props} />;
}

function ToogleButtonGroupSingle<T>({
  buttons,
  value,
  onChange,
  className,
  size,
}: ToggleButtonSingleProps<T>) {
  return (
    <Fragment>
      {buttons.map(button => (
        <OutlineButton
          key={button.text}
          type="button"
          {...button}
          className={{
            ...button.className,
            base: twMerge(
              className?.button,
              stylesBySize[size || 'medium'],
              button.className?.base,
            ),
          }}
          onClick={e => {
            onChange?.(button.value, e);
          }}
          active={value === button.value}
        />
      ))}
    </Fragment>
  );
}

function ToogleButtonGroupMulti<T>({
  buttons,
  value = [],
  onChange,
  className,
  size,
}: ToggleButtonMultiProps<T>) {
  return (
    <Fragment>
      {buttons.map(button => (
        <OutlineButton
          key={button.text}
          type="button"
          {...button}
          className={{
            ...button.className,
            base: twMerge(
              className?.button,
              stylesBySize[size || 'medium'],
              button.className?.base,
            ),
          }}
          onClick={e => {
            onChange?.(
              value.includes(button.value)
                ? value.filter(v => v !== button.value)
                : [...value, button.value],
              e,
            );
          }}
          active={value.includes(button.value)}
        />
      ))}
    </Fragment>
  );
}
