import React from 'react';
import { BaseInputProps } from './BaseInput';
import TextInput from './TextInput';
import useToggle from '@/data/hook/useToggle';
import Text from '../dataDisplay/Text';
import { LoadingIcon } from '../../icons';
import { useTranslation } from 'react-i18next';
import { PlusIcon, SearchIcon, XIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import Skeleton from '../Skeleton';
import { twJoin, twMerge } from 'tailwind-merge';
import { Tooltip } from '../dataDisplay/Tooltip';
import ConditionalWrapper from '../ConditionalWrapper';
import InfinityList from '../InfinityList';
import ConditionalRenderer from '../ConditionalRenderer';

export type SearchInputMode = 'single' | 'multiple';

type keyType = string | number;

export type CustomInputProps = Omit<
  BaseInputProps,
  'onChange' | 'value' | 'register'
> & {
  ref?: React.Ref<HTMLInputElement>;
};
export interface SearchOption<T> {
  key: keyType;
  value: T;
  isDisabled?: boolean;
  tooltipMessage?: string;
  selected?: boolean;
}

export type SearchInputProps<T> = BaseInputProps & {
  options: SearchOption<T>[];
  input?: CustomInputProps;
  onSearch?: React.ChangeEventHandler<HTMLInputElement>;
  onSelect(option: T): void;
  displayName: (option: T) => string;
  onDeselect?(): void;
  value: string;
  isSearching?: boolean;
  isLoading?: boolean;
  hasItemSelected?: boolean;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  onReachEnd?(): unknown | Promise<unknown>;
  inputIcon?: JSX.Element;
  mode?: SearchInputMode;
};

function SearchInput<T>({
  displayName,
  input,
  onSearch,
  onSelect,
  options,
  value,
  isLoading,
  isSearching,
  onDeselect,
  hasItemSelected,
  hasNextPage,
  isFetchingNextPage,
  onReachEnd,
  inputIcon,
  mode = 'single',
}: SearchInputProps<T>) {
  const { close, open, toggle, isOpen } = useToggle();

  const selectOption = (option: T) => {
    if (mode === 'single') close();
    onSelect(option);
  };

  const icon =
    hasItemSelected && onDeselect ? (
      <XIcon
        data-testid="deselectIcon"
        className="text-error cursor-pointer"
        onClick={onDeselect}
      />
    ) : (
      <span onClick={toggle} className="cursor-pointer">
        {inputIcon ?? <SearchIcon />}
      </span>
    );

  const onBlur: React.FocusEventHandler<HTMLInputElement> = e => {
    const relatedTarget = e.relatedTarget;
    if (!relatedTarget?.getAttribute('data-ignore-blur')) {
      close();
    }
  };
  if (isLoading) {
    return (
      <Skeleton
        testId="loadingInput"
        className={twMerge(
          'w-full h-8 rounded-md bg-primary-content',
          input?.className?.base ?? 'max-w-sm',
        )}
      />
    );
  }

  return (
    <div
      className={twMerge('flex flex-col relative', input?.className?.base)}
      onMouseLeave={close}
    >
      <TextInput
        {...input}
        onBlur={mode === 'single' ? onBlur : undefined}
        icon={input?.disabled ? undefined : icon}
        onFocus={open}
        onClick={open}
        onChange={e => {
          open();
          onSearch?.(e);
        }}
        value={value}
      />

      <div className="flex flex-col relative w-full z-30">
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="absolute w-full overflow-hidden shadow-default rounded-b-md bg-base-100"
              initial={{
                height: 0,
                opacity: 0.75,
                zIndex: 200,
              }}
              animate={{
                height: 'auto',
                opacity: 1,
                x: 0,
                y: 0,
              }}
              exit={{
                height: 0,
              }}
            >
              <Options
                displayName={displayName}
                onSelect={selectOption}
                options={options}
                isSearching={isSearching}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                onReachEnd={onReachEnd}
                mode={mode}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

interface OptionsProps<T> {
  options: SearchOption<T>[];
  onSelect(option: T): void;
  displayName(option: T): string;
  isSearching?: boolean;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  onReachEnd?(): unknown | Promise<unknown>;
  mode: SearchInputMode;
}

const Options = <T,>({
  displayName,
  onSelect,
  options,
  isSearching,
  hasNextPage,
  isFetchingNextPage,
  onReachEnd,
  mode,
}: OptionsProps<T>) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassInput',
  });

  function itemStyle<T>(option: SearchOption<T>) {
    const { selected, isDisabled } = option;
    if (selected) return 'hidden';
    if (isDisabled)
      return 'pointer-events-none text-neutral hover:text-neutral/50 bg-neutral/20';

    return 'cursor-pointer hover:text-primary hover:bg-primary/10';
  }

  const notSelectedOptions = options.filter(option => !option.selected);

  return (
    <InfinityList
      className="flex flex-col w-full overflow-x-hidden min-h-[2rem] max-h-[10rem]"
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      onReachEnd={onReachEnd}
      scroll
    >
      <ConditionalRenderer condition={isSearching}>
        <LoadingIcon className="w-8 h-8 text-primary/40 self-center" />
      </ConditionalRenderer>

      <ConditionalRenderer
        condition={!isSearching && notSelectedOptions.length}
      >
        {notSelectedOptions.map(
          ({ key, value, isDisabled, tooltipMessage, selected }) => {
            return (
              <ConditionalWrapper
                condition={Boolean(isDisabled)}
                wrapper={
                  <Tooltip
                    placement="auto"
                    className="text-primary"
                    classNameContainer="w-full"
                    color="lightBlue"
                    text={tooltipMessage}
                  />
                }
                key={key}
              >
                <li
                  role="option"
                  aria-selected
                  className={twJoin(
                    'p-0 w-full flex rounded-md transition-all ease-in-out duration-150 items-center',
                    itemStyle({ isDisabled, selected } as SearchOption<T>),
                  )}
                >
                  <button
                    disabled={isDisabled}
                    type="button"
                    data-testid={`option-${key}`}
                    className="text-left grow flex p-2 font-500 text-14 gap-2 items-center justify-between"
                    data-ignore-blur="true"
                    onClick={() => onSelect(value)}
                  >
                    {displayName(value)}
                    <ConditionalRenderer condition={mode === 'multiple'}>
                      <PlusIcon className="w-4" />
                    </ConditionalRenderer>
                  </button>
                </li>
              </ConditionalWrapper>
            );
          },
        )}
      </ConditionalRenderer>

      <ConditionalRenderer
        condition={!isSearching && !notSelectedOptions.length}
      >
        <Text
          text={`${t('warnings.noResults')} ༼ つ ◕_◕ ༽つ`}
          className="pt-2 text-primary self-center"
        />
      </ConditionalRenderer>
    </InfinityList>
  );
};

export default SearchInput;
