import { FC, memo, useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { XIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';

import { BaseFilters } from '@/models/Service';
import InfiniteSearchInput, {
  InfiniteSearchInputProps,
} from './InfiniteSearchInput';
import { scrollBarYClassName } from '@/utils/scrollBarClassName';
import { SearchOption } from './SearchInput';
import IconButton from '../buttons/IconButton';
import { Tooltip } from '../dataDisplay/Tooltip';
import ConditionalRenderer from '../ConditionalRenderer';

type ChoiceSelectorProps<Filter extends BaseFilters, Model> = Omit<
  InfiniteSearchInputProps<Filter, Model>,
  'onSelect'
> & {
  render: FC<{ model: Model }>;
  getKey?: (item: Model) => string | number;
  onChange(value: Model[]): void;
  defaultValue?: Model[];
};

function ChoiceSelectorComponent<Filter extends BaseFilters, Model>(
  props: ChoiceSelectorProps<Filter, Model>,
) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const {
    className,
    getKey = JSON.stringify,
    render: Render,
    onChange,
    defaultValue = [],
  } = props;

  const [values, setValues] = useState<Model[]>(defaultValue);

  const handleRemove = useCallback(
    (value: Model) => {
      const key = getKey(value);

      const newValue = values.filter(value => getKey(value) !== key);

      setValues(newValue);

      onChange(newValue);
    },
    [values, getKey, onChange],
  );
  const handleChosen = useCallback(
    (value: Model) => {
      const key = getKey(value);
      const wasChosen = values.some(value => getKey(value) === key);

      if (wasChosen) {
        handleRemove(value);
      } else {
        const newValue = [...values, value];

        setValues(newValue);
        onChange(newValue);
      }
    },
    [values, getKey, handleRemove, onChange],
  );

  const createOption = useCallback(
    (item: Model, index: number): SearchOption<Model> => ({
      value: item,
      key: index,
      selected: values.some(option => getKey(option) === getKey(item)),
    }),
    [values, getKey],
  );

  return (
    <section className={twMerge('flex flex-col gap-2.5', className?.base)}>
      <InfiniteSearchInput
        {...props}
        onSelect={handleChosen}
        createOption={createOption}
        clearOnSelect
        mode="multiple"
      />
      <ConditionalRenderer condition={values.length}>
        <AnimatePresence>
          <motion.ul
            layout
            animate={{ height: 'auto' }}
            transition={{
              type: 'spring',
              stiffness: 300,
              damping: 30,
              duration: 0.25,
            }}
            className={twMerge(
              'flex flex-col max-h-[11.25rem] gap-2.5 overflow-x-hidden bg-base-100 w-full pr-1',
              'scrollbar-none',
              className?.ul,
              scrollBarYClassName,
            )}
          >
            {values.map(value => (
              <motion.li
                layout
                key={getKey(value)}
                className={twMerge(
                  'flex items-center justify-between max-w-full',
                  className?.li,
                )}
              >
                <Render model={value} />
                <Tooltip text={t('remove')}>
                  <IconButton
                    icon={<XIcon className="text-error w-5 h-5 shrink-0" />}
                    onClick={() => handleRemove(value)}
                  />
                </Tooltip>
              </motion.li>
            ))}
          </motion.ul>
        </AnimatePresence>
      </ConditionalRenderer>
    </section>
  );
}

const ChoiceSelector = memo(ChoiceSelectorComponent) as <
  Filter extends BaseFilters,
  Model,
>(
  props: ChoiceSelectorProps<Filter, Model>,
) => JSX.Element;

export default ChoiceSelector;
